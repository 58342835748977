// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agence-js": () => import("./../../../src/pages/agence.js" /* webpackChunkName: "component---src-pages-agence-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projets-js": () => import("./../../../src/pages/projets.js" /* webpackChunkName: "component---src-pages-projets-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-one-projet-one-projet-js": () => import("./../../../src/templates/oneProjet/oneProjet.js" /* webpackChunkName: "component---src-templates-one-projet-one-projet-js" */)
}

