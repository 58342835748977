module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-103145856-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"duration":2000},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WebDevVision | Agence de développement Web et Mobile","short_name":"WebDevVision | Agence de développement Web et Mobile","start_url":"/","icon":"src/images/icons/icon.png","icons":[{"src":"src/images/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/images/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"src/images/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"src/images/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"shortcuts":[{"name":"L'agence","short_name":"Agence","description":"L'agence WebdevVision","url":"/agence","icons":[{"src":"src/images/icons/icon.png","sizes":"96x96"}]},{"name":"Nos services","short_name":"Services","description":"Les services de WebDevVision","url":"/services","icons":[{"src":"src/images/icons/icon.png","sizes":"96x96"}]},{"name":"Nos projets","short_name":"Projets","description":"Les projets de WebDevVision","url":"/projets","icons":[{"src":"src/images/icons/icon.png","sizes":"96x96"}]},{"name":"Nous contacter","short_name":"Contacter","description":"Contacter WebDevVision","url":"/contact","icons":[{"src":"src/images/icons/icon.png","sizes":"96x96"}]}],"description":"WebDevVision est une agence web situé sur Gaillac, passionnée et engagée dans la stratégie digitale, la conception UI / UX et le développement d’application web et mobile.","background_color":"#F5F0E1","theme_color":"#F5F0E1","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"21c8021b1efe63300b9e9c5546c42782"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
